import { useContext } from 'react';
import { Status } from '../state/lifeline.state';
import { FirebaseContext } from '../Firebase';
import {
	WaitingArea,
	PreQuestion,
	Question,
	PostQuestion,
	Winner,
} from '../components/Host';

function Host() {
	const { game } = useContext(FirebaseContext);

	const renderHostStatus = {
		[Status.WaitingForPlayers]: <WaitingArea />,
		[Status.PreQuestion]: <PreQuestion />,
		[Status.Question]: <Question />,
		[Status.PostQuestion]: <PostQuestion />,
		[Status.Leaderboard]: <Winner />,
	};

	return <div>{renderHostStatus[game.gameStatus]}</div>;
}

export default Host;
