export const icons = [
	'nes-ash',
	'nes-pokeball',
	'nes-bulbasaur',
	'nes-charmander',
	'nes-squirtle',
	'nes-mario',
	'nes-kirby',
];

export const zodiacIcons = [
	'rat',
	'ox',
	'tiger',
	'rabbit',
	'dragon',
	'snake',
	'horse',
	'goat',
	'monkey',
	'rooster',
	'dog',
	'pig',
];
