import { useState, useEffect } from 'react';
import { sortBy, findIndex } from 'lodash';
import { playerDb } from '../index';
import { getWithExpiry } from '../utils/localStorage';
import { SESSION_KEY } from '../constant/session';

const useFetchPlayers = () => {
	const [players, setPlayers] = useState([]);
	const [player, setPlayer] = useState({
		gameId: 'SAM',
		name: '',
		points: 0,
	});

	const sessionKey = getWithExpiry(SESSION_KEY);
	const sortedPlayers = sortBy(players, sortBy(players, ['points', 'name']));

	function fetchAllPlayers() {
		playerDb.onSnapshot((snapshot) => {
			const players = [];
			snapshot.forEach((doc) => {
				players.push(doc.data());
			});
			setPlayers(players);
		});
	}

	const currentPosition = findIndex(
		sortedPlayers,
		(user) => user?.name === player?.name
	);

	useEffect(() => {
		if (sessionKey) {
			playerDb.doc(sessionKey).onSnapshot((doc) => {
				const { gameId, name, points } = doc.data();
				setPlayer({ gameId, name, points });
			});
		}
	}, [sessionKey]);

	useEffect(() => {
		fetchAllPlayers();
	}, []);

	return {
		players: players,
		player: player,
		sortedPlayers: sortedPlayers,
		currentPosition: currentPosition,
	};
};

export default useFetchPlayers;
