import { createContext, useEffect, useState } from 'react';
import { playerDb, hostDb, questionDb } from './index';
import { Context, Game, Question, Player } from './constant/firebase';

export const FirebaseContext = createContext<Context>({
	game: {
		currentQuestion: 'firstQuestion',
		gameName: 'SAM',
		gameStatus: 'waitingForPlayers',
		winner: {
			character: '',
			gameId: 'SAM',
			name: '',
			points: 0,
		},
	},
	allPlayers: [],
	question: {
		answered: [],
		answers: [],
		question: '',
		rightAnswer: 0,
	},
});

function Firebase({ children }: { children: React.ReactNode }) {
	const [game, getGame] = useState<Game>({
		currentQuestion: 'firstQuestion',
		gameName: 'SAM',
		gameStatus: 'waitingForPlayers',
		winner: {
			character: '',
			gameId: 'SAM',
			name: '',
			points: 0,
		},
	});
	const [question, getQuestion] = useState<Question>({
		answered: [],
		answers: [],
		rightAnswer: 0,
		question: '',
	});
	const [allPlayers, getAllPlayers] = useState<Player[]>([]);

	function fetchAllPlayers() {
		playerDb.onSnapshot((snapshot) => {
			const players = [];
			snapshot.forEach((doc) => {
				players.push(doc.data());
			});
			getAllPlayers(players);
		});
	}

	useEffect(() => {
		fetchAllPlayers();
	}, []);

	function fetchGame() {
		hostDb.onSnapshot({ includeMetadataChanges: true }, (doc) => {
			const { currentQuestion, gameName, gameStatus, winner } = doc.data();
			getGame({ currentQuestion, gameName, gameStatus, winner });
		});
	}

	useEffect(() => {
		fetchGame();
	}, []);

	function fetchQuestion() {
		questionDb.doc(game.currentQuestion).onSnapshot((doc) => {
			const { answered, answers, rightAnswer, question } = doc.data();
			getQuestion({ answered, answers, rightAnswer, question });
		});
	}

	useEffect(() => {
		fetchQuestion();
	}, [game.currentQuestion]);

	return (
		<FirebaseContext.Provider value={{ game, question, allPlayers }}>
			{children}
		</FirebaseContext.Provider>
	);
}

export default Firebase;
