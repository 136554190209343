export const question = {
	firstQuestion: 'Question 1',
	secondQuestion: 'Question 2',
	thirdQuestion: 'Question 3',
	fourthQuestion: 'Question 4',
	fifthQuestion: 'Question 5',
	sixthQuestion: 'Question 6',
	seventhQuestion: 'Question 7',
	lastQuestion: 'Last Question!!!',
};
