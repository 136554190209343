import { useState, useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Container } from '@material-ui/core';
import Host from './pages/Host';
import Player from './pages/Player';
import GameStarted from './pages/GameStarted';
import { playerDb } from './index';
import { setWithExpiry, getWithExpiry } from './utils/localStorage';
import { SESSION_KEY } from './constant/session';
import { icons } from './constant/icons';

function Lifeline() {
	const [name, setName] = useState('');
	const [character, setCharacter] = useState('');
	const history = useHistory();

	function handleSubmit(e: React.FormEvent) {
		e.preventDefault();

		const playerStartingData = {
			name: name,
			points: 0,
			gameId: 'SAM',
			character: character,
		};

		playerDb
			.add(playerStartingData)
			.then((doc) => {
				const thirtyMinutes = 30 * 60 * 1000;
				setWithExpiry(SESSION_KEY, doc.id, thirtyMinutes);
				history.push('/player');
			})
			.catch((error) => console.log(error));
	}

	return (
		<Container maxWidth='sm' style={{ textAlign: 'center' }}>
			<h1 style={{ padding: '12px' }}>
				Welcome to <span className='nes-text is-success'>Aden's</span> lifeline!
			</h1>
			<div className='nes-container  is-centered'>
				<form onSubmit={handleSubmit}>
					<div className='nes-field' style={{ marginBottom: '32px' }}>
						<label htmlFor='name_field'>Enter your name</label>
						<input
							type='text'
							id='name_field'
							className='nes-input'
							minLength={2}
							maxLength={31}
							pattern='\S.*\S'
							onChange={(e) => setName(e.target.value)}
							required
						/>
					</div>
					<div style={{ marginBottom: '32px' }}>
						<p>Choose Character</p>
						<section className='icon-list'>
							{icons.map((icon) => (
								<>
									<input
										type='radio'
										id={icon}
										name='character'
										value={icon}
										onChange={(e) => setCharacter(e.target.value)}
										required></input>

									<label htmlFor={icon}>
										<i className={icon}></i>
									</label>
								</>
							))}
						</section>
					</div>

					<button
						type='submit'
						className={`nes-btn ${
							name.trim().length > 1 &&
							name.trim().length < 30 &&
							character.length > 0
								? 'is-success'
								: 'is-disabled'
						}`}>
						Let's go!
					</button>
				</form>
			</div>
		</Container>
	);
}

function App() {
	const sessionKey = getWithExpiry(SESSION_KEY);
	const history = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname !== '/host') {
			if (sessionKey) {
				history.push('/player');
			} else {
				history.push('/');
			}
		}
	}, [sessionKey, history, pathname]);

	return (
		<Switch>
			<Route path='/player' component={Player} />
			<Route path='/host' component={Host} />
			<Route path='/gameStarted' component={GameStarted} />
			<Route exact path='/' component={Lifeline} />
		</Switch>
	);
}

export default App;
