export function setWithExpiry(key: string, value: string, ttl: number): void {
	const now = new Date();

	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	};

	localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string): string | null {
	const itemStr = localStorage.getItem(key);
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();

	if (!item.expiry) {
		localStorage.removeItem(key);
		return null;
	}
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
}
