import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../Firebase';
import useAudio from '../../hooks/useAudio';
import { Status } from '../../state/lifeline.state';
import { question as gameQuestion } from '../../constant/question';
import { hostDb } from '../../index';

function PreQuestion() {
	const seconds = 5000;
	const { question, game } = useContext(FirebaseContext);

	const [counter, setCounter] = useState(0);

	useEffect(() => {
		const timer =
			counter < 100 && setTimeout(() => setCounter(counter + 2), 85);
		return () => clearTimeout(timer);
	}, [counter]);

	useEffect(() => {
		let timer;

		timer = setTimeout(() => {
			const gameStatus = { gameStatus: Status.Question };
			hostDb.update(gameStatus).catch((error) => console.error(error));
		}, seconds);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	useAudio(`/sounds/Monkeys-Spinning-Monkeys.mp3`);

	return (
		<div>
			<progress
				className='nes-progress is-success'
				value={counter}
				max='100'></progress>

			<p style={{ textAlign: 'center', fontSize: '64px' }}>
				{gameQuestion[game.currentQuestion]}
			</p>
			<h1
				style={{ textAlign: 'center', fontSize: '56px', margin: '250px 50px' }}>
				{question.question}
			</h1>
		</div>
	);
}

export default PreQuestion;
