import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../Firebase';
import useAudio from '../../hooks/useAudio';
import { hostDb } from '../../index';
import { Status } from '../../state/lifeline.state';

function Question() {
	const { game, question, allPlayers } = useContext(FirebaseContext);
	let seconds = 10000;

	const [counter, setCounter] = useState(0);

	useEffect(() => {
		const timer =
			counter < 100 && setTimeout(() => setCounter(counter + 1), 85);
		return () => clearTimeout(timer);
	}, [counter]);

	useEffect(() => {
		let timer;

		if (game.gameStatus === Status.Question) {
			timer = setTimeout(() => {
				const gameStatus = { gameStatus: Status.PostQuestion };
				hostDb.update(gameStatus).catch((error) => console.error(error));
			}, seconds);
		}

		return () => {
			clearTimeout(timer);
		};
	}, []);

	useAudio(`/sounds/Monkeys-Spinning-Monkeys.mp3`);

	const mapAnswers = question.answers.map((answer) => (
		<button
			className='answer-btn'
			type='button'
			style={{
				height: '300px',
				margin: '25px',
				fontSize: '38px',
				color: 'white',
				width: '45%',
				border: 'none',
			}}
			key={answer}>
			{answer}
		</button>
	));

	return (
		<div>
			<progress
				className='nes-progress is-error'
				value={counter}
				max='100'></progress>
			<h1 style={{ textAlign: 'center', fontSize: '48px', padding: '12px' }}>
				{question.question}
			</h1>
			<h2 style={{ textAlign: 'center', fontSize: '32px', marginTop: '48px' }}>
				Number of people who voted : {question.answered.length} /{' '}
				{allPlayers.length}
			</h2>
			<div style={{ marginTop: '150px', textAlign: 'center' }}>
				{mapAnswers}
			</div>
		</div>
	);
}

export default Question;
