import { useState, useEffect, SetStateAction, Dispatch } from 'react';

const useAudio = (
	url,
	volume = 0.5
): [boolean, () => void, Dispatch<SetStateAction<HTMLAudioElement>>] => {
	const [audio, setAudio] = useState(new Audio(url));
	const [playing, setPlaying] = useState(true);

	const toggle = () => setPlaying(!playing);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [playing, audio]);

	useEffect(() => {
		audio.volume = volume;
		audio.addEventListener('ended', () => setPlaying(true));
		return () => {
			audio.removeEventListener('ended', () => setPlaying(false));
			audio.pause();
		};
	}, [audio]);

	return [playing, toggle, setAudio];
};

export default useAudio;
