import { useContext } from 'react';
import QRCode from 'qrcode.react';
import { FirebaseContext } from '../../Firebase';
import useAudio from '../../hooks/useAudio';
import { Status } from '../../state/lifeline.state';
import { hostDb } from '../../index';

function WaitingArea() {
	const { allPlayers } = useContext(FirebaseContext);

	function startGame() {
		const startGameStatus = { gameStatus: Status.PreQuestion };
		hostDb.update(startGameStatus).catch((error) => console.error(error));
	}

	const [, toggle] = useAudio(
		`/sounds/campfire-vibes-w-artificial.music-scloudtomp3downloader.com_.mp3`,
		0.3
	);

	return (
		<div>
			<h1 style={{ textAlign: 'center', fontSize: '64px', padding: '32px' }}>
				<span className='nes-text is-primary'>
					{allPlayers.length} players{' '}
				</span>
				in the lobby
			</h1>
			<div style={{ textAlign: 'center' }}>
				<QRCode
					value='https://lifelinexseedly.onrender.com'
					size={256}
					onClick={toggle}
				/>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					margin: '30px',
				}}>
				<div className='player-lobby'>
					{allPlayers.map((player) => (
						<p key={player.name}>
							<i className={player.character}></i>
							{player.name}
						</p>
					))}
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<button
					type='button'
					className='nes-btn is-primary'
					onClick={startGame}>
					Start Game!
				</button>
			</div>
		</div>
	);
}

export default WaitingArea;
