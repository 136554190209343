import Confetti from 'react-confetti';
import useFetchPlayers from '../../hooks/useFetchPlayers';
import useAudio from '../../hooks/useAudio';

function Winner() {
	const { sortedPlayers } = useFetchPlayers();

	useAudio(`/sounds/alexander-nakarada-superepic.mp3`);

	const winner = (
		<div
			className='nes-container with-title is-centered'
			style={{ width: '50%' }}>
			<p className='title' style={{ fontSize: '4rem' }}>
				<i className='nes-icon trophy is-large'></i>
				WINNER!
				<i className='nes-icon trophy is-large'></i>
			</p>
			<h1 style={{ textAlign: 'center' }}>
				Congrats!!!!!!!! 🥳
				<br />
				<i className={sortedPlayers[0]?.character}></i>
				<br />
				<span
					style={{
						color: 'goldenrod',
						textDecoration: 'underline',
						fontSize: '72px',
					}}>
					{sortedPlayers[0]?.name || 'NOBODY'}
				</span>
				<br />
				won with
				<br />
				<span
					style={{
						color: 'purple',
						textDecoration: 'underline',
						fontSize: '96px',
					}}>
					{sortedPlayers[0]?.points || '0'}
				</span>{' '}
				<br /> points!
			</h1>
		</div>
	);

	const finalResults = sortedPlayers.slice(1, 3).map((player, index) => {
		const results = [
			{
				result: 'FIRST RUNNER UP!',
				font: '2rem',
				color: 'darkkhaki',
				secondaryColor: 'pink',
				text: 'Hardworking',
				secondaryText: 'with',
			},
			{
				result: 'SECOND RUNNER UP!',
				font: '2rem',
				color: 'indianred',
				secondaryColor: 'darkseagreen',
				text: 'Dedicated',
				secondaryText: 'with',
			},
		];

		return (
			<div
				className='nes-container with-title is-centered'
				style={{ width: '50%' }}
				key={player?.name}>
				<p className='title' style={{ fontSize: '1rem' }}>
					<i className='nes-icon coin'></i>
					{results[index].result}
					<i className='nes-icon coin'></i>
				</p>
				<h1 style={{ textAlign: 'center' }}>
					{results[index].text}
					<br />
					<i className={player?.character}></i>
					<br />
					<span
						style={{
							color: results[index].color,
							textDecoration: 'underline',
							fontSize: results[index].font,
						}}>
						{player?.name || 'NOBODY'}
					</span>
					<br />
					{results[index].secondaryText}
					<br />
					<span
						style={{
							color: results[index].secondaryColor,
							textDecoration: 'underline',
							fontSize: '58px',
						}}>
						{player?.points || '0'}
					</span>{' '}
					<br /> points!
				</h1>
			</div>
		);
	});

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<Confetti width={2600} height={1200} />
			{winner}
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				{finalResults}
			</div>
		</div>
	);
}

export default Winner;
