import { useContext, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Status } from '../state/lifeline.state';
import { playerDb, questionDb } from '../index';
import { FirebaseContext } from '../Firebase';
import useAudio from '../hooks/useAudio';
import { getWithExpiry } from '../utils/localStorage';
import { SESSION_KEY } from '../constant/session';
import { Grid, Button } from '@material-ui/core';
import useFetchPlayers from '../hooks/useFetchPlayers';

import 'firebase/firestore';

function Leaderboard() {
	const { players, currentPosition } = useFetchPlayers();

	return (
		<div className='nes-container with-title is-centered'>
			<p className='title'>Game Over</p>
			<p>Thank you for playing</p>
			<p className='nes-text is-error' style={{ fontSize: '2rem' }}>
				Lifeline!
			</p>
			<p>You are</p>
			<p className='nes-text is-success' style={{ fontSize: '4rem' }}>
				{currentPosition + 1}
				<span className='nes-text is-disabled'>/</span>
				<span className='nes-text is-primary'>{players?.length}</span>
			</p>
			<p>Please look at the main screen for the results</p>
		</div>
	);
}

function PostQuestion() {
	const { player, currentPosition } = useFetchPlayers();

	return (
		<div className='nes-container with-title is-centered'>
			<p className='title'>Scoreboard</p>
			<div>
				<p>You currently have </p>
				<p>
					<span className='nes-text is-success' style={{ fontSize: '64px' }}>
						{player.points}
					</span>
				</p>
				<p>points</p>
			</div>
			<br />

			<p>
				You are currently in
				<span className='nes-text is-warning'> {currentPosition + 1} </span>
				position
			</p>
		</div>
	);
}

function Answers() {
	const { game, question } = useContext(FirebaseContext);

	const [isCorrect, setIsCorrect] = useState(false);
	const [isWrong, setIsWrong] = useState(false);
	const [displayAnswers, setDisplayAnswers] = useState(true);
	const sessionKey = getWithExpiry(SESSION_KEY);

	const correctAnswer = question.answers[question.rightAnswer];

	const [counter, setCounter] = useState(100);

	const [player, setPlayer] = useState({
		gameId: 'SAM',
		name: '',
		points: 0,
	});

	useEffect(() => {
		playerDb.doc(sessionKey).onSnapshot((doc) => {
			const { gameId, name, points } = doc.data();
			setPlayer({ gameId, name, points });
		});
	}, [sessionKey]);

	useEffect(() => {
		const timer = counter > 0 && setTimeout(() => setCounter(counter - 2), 85);
		return () => clearTimeout(timer);
	}, [counter]);

	const handleRightAnswer = (chosenAnswer: string, index: number) => {
		const answerIndex = index;
		if (chosenAnswer === correctAnswer) {
			playerDb.doc(sessionKey).update({
				points: firebase.firestore.FieldValue.increment(100 + counter),
			});

			questionDb.doc(game.currentQuestion).update({
				answered: firebase.firestore.FieldValue.arrayUnion({
					name: player.name,
					chosenAnswer: answerIndex,
				}),
			});

			setDisplayAnswers(false);
			setIsCorrect(true);
		} else {
			questionDb.doc(game.currentQuestion).update({
				answered: firebase.firestore.FieldValue.arrayUnion({
					name: player.name,
					chosenAnswer: answerIndex,
				}),
			});
			setDisplayAnswers(false);
			setIsWrong(true);
		}
	};

	const mapAnswers = question.answers.map((answer, index) => {
		return (
			<Button
				type='button'
				className='answer-btn'
				fullWidth
				size='large'
				key={answer}
				style={{ marginTop: '10px', marginBottom: '10px' }}
				onClick={() => handleRightAnswer(answer, index)}
			/>
		);
	});

	useEffect(() => {
		setDisplayAnswers(true);
		setIsCorrect(false);
		setIsWrong(false);
	}, []);

	return (
		<Grid container spacing={0} direction='column'>
			{isCorrect === true && (
				<div className='nes-container with-title is-centered'>
					<p className='title'>
						<i className='nes-icon is-medium star'></i>
						<i className='nes-icon is-medium star'></i>
						<i className='nes-icon is-medium star'></i>
					</p>

					<p>WOOHOO!</p>
					<p>
						You got the <span className='nes-text is-success'>right</span>
						<br />
						answer
					</p>
				</div>
			)}
			{isWrong === true && (
				<div className='nes-container with-title is-centered'>
					<p className='title'>
						<i className='nes-icon is-medium star is-empty'></i>
						<i className='nes-icon is-medium star is-empty'></i>
						<i className='nes-icon is-medium star is-empty'></i>
					</p>

					<p>
						<span className='nes-text is-error'>OOPS!</span>
					</p>
					<p>Try harder next time</p>
				</div>
			)}
			{displayAnswers && (
				<div className='nes-container with-title'>
					<p className='title'>Select answer</p>
					{mapAnswers}
				</div>
			)}
		</Grid>
	);
}

function PreQuestion() {
	return (
		<Grid>
			<div className='nes-container is-centered'>
				<p>
					Get <span className='nes-text is-primary'>ready!</span>
				</p>
				<p>Look at the main screen</p>
			</div>
		</Grid>
	);
}

function WaitingForPlayers() {
	return (
		<div style={{ textAlign: 'center' }}>
			<h1 style={{ fontSize: '32px' }}>Waiting for the rest to join!</h1>
			<p> Press on the ❤️ for some excellent music</p>
		</div>
	);
}

export default function Player() {
	const { game } = useContext(FirebaseContext);
	const [playing, toggle] = useAudio('/sounds/positive-hip-hop.mp3');

	const renderGameStatus = {
		[Status.WaitingForPlayers]: <WaitingForPlayers />,
		[Status.PreQuestion]: <PreQuestion />,
		[Status.Question]: <Answers />,
		[Status.PostQuestion]: <PostQuestion />,
		[Status.Leaderboard]: <Leaderboard />,
	};

	return (
		<div
			style={{
				margin: 'auto',
				height: '100%',
				width: '100%',
			}}>
			<div style={{ textAlign: 'center', margin: '25px' }}>
				<Button onClick={toggle} style={{ border: 'none', padding: 0 }}>
					<i
						className={`nes-icon is-large heart ${
							playing ? '' : 'is-empty'
						}`}></i>
				</Button>
			</div>
			{renderGameStatus[game.gameStatus]}
		</div>
	);
}
