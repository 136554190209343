import { useContext, useEffect, useState } from 'react';
import countBy from 'lodash/countBy';
import maxBy from 'lodash/maxBy';
import { FirebaseContext } from '../../Firebase';
import useFetchPlayers from '../../hooks/useFetchPlayers';
import useAudio from '../../hooks/useAudio';
import { hostDb } from '../../index';
import { Status } from '../../state/lifeline.state';

function PostQuestion() {
	const { game, question, allPlayers } = useContext(FirebaseContext);

	const [counter, setCounter] = useState(0);

	const { sortedPlayers } = useFetchPlayers();

	useAudio(`/sounds/Fluffing-a-Duck.mp3`);

	const rightAnswer = question.answers[question.rightAnswer];
	const totalAnswers = question.answered.map((ans) => ans.chosenAnswer);
	const countTotalAnswers = countBy(totalAnswers);

	const winner = maxBy(allPlayers, 'points');

	let seconds = 15000;
	let currentQuestion;

	function nextQuestion() {
		if (
			game.gameStatus === Status.PostQuestion &&
			game.currentQuestion !== 'lastQuestion'
		) {
			const gameStatus = {
				gameStatus: Status.PreQuestion,
				currentQuestion: currentQuestion,
				winner: winner,
			};
			hostDb.update(gameStatus).catch((error) => console.error(error));
		} else {
			const gameStatus = {
				gameStatus: Status.Leaderboard,
				currentQuestion: currentQuestion,
				winner: winner,
			};
			hostDb.update(gameStatus).catch((error) => console.error(error));
		}
	}

	if (game.currentQuestion === 'firstQuestion') {
		currentQuestion = 'secondQuestion';
	} else if (game.currentQuestion === 'secondQuestion') {
		currentQuestion = 'thirdQuestion';
	} else if (game.currentQuestion === 'thirdQuestion') {
		currentQuestion = 'fourthQuestion';
	} else if (game.currentQuestion === 'fourthQuestion') {
		currentQuestion = 'fifthQuestion';
	} else if (game.currentQuestion === 'fifthQuestion') {
		currentQuestion = 'sixthQuestion';
	} else if (game.currentQuestion === 'sixthQuestion') {
		currentQuestion = 'seventhQuestion';
	} else if (game.currentQuestion === 'seventhQuestion') {
		currentQuestion = 'lastQuestion';
	} else {
		currentQuestion = 'lastQuestion';
	}

	const leaderboard = (
		<div className='nes-table-responsive'>
			<table
				className='nes-table is-bordered is-centered'
				style={{
					marginLeft: 'auto',
					marginRight: 'auto',
					fontSize: '24px',
					textAlign: 'center',
				}}>
				<thead>
					<tr>
						<th>
							<span className='nes-text is-primary'>First</span> place
						</th>
						<th>
							<span className='nes-text is-warning'>Second</span> place
						</th>
						<th>
							<span className='nes-text is-error'>Third</span> place
						</th>
						<th>
							<span className='nes-text is-success'>Fourth</span> place
						</th>
						<th>
							<span className='nes-text is-disabled'>Fifth</span> place
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{sortedPlayers[0]?.name}</td>
						<td>{sortedPlayers[1]?.name}</td>
						<td>{sortedPlayers[2]?.name}</td>
						<td>{sortedPlayers[3]?.name}</td>
						<td>{sortedPlayers[4]?.name}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);

	useEffect(() => {
		const timer =
			counter < 100 && setTimeout(() => setCounter(counter + 1), 135);
		return () => clearTimeout(timer);
	}, [counter]);

	useEffect(() => {
		let timer;

		if (
			game.gameStatus === Status.PostQuestion &&
			game.currentQuestion !== 'lastQuestion'
		) {
			timer = setTimeout(() => {
				const gameStatus = {
					gameStatus: Status.PreQuestion,
					currentQuestion: currentQuestion,
					winner: winner,
				};
				hostDb.update(gameStatus).catch((error) => console.error(error));
			}, seconds);
		} else {
			timer = setTimeout(() => {
				const gameStatus = {
					gameStatus: Status.Leaderboard,
					currentQuestion: currentQuestion,
					winner: winner,
				};
				hostDb.update(gameStatus).catch((error) => console.error(error));
			}, seconds);
		}

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<div>
			<progress
				className='nes-progress is-warning'
				value={counter}
				max='100'></progress>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<button
					type='button'
					className='nes-btn is-primary'
					onClick={nextQuestion}>
					Next Question!
				</button>
			</div>
			<h1 style={{ textAlign: 'center', fontSize: '36px', padding: '52px' }}>
				The correct answer is:{' '}
				<span style={{ color: 'green' }}>{rightAnswer}</span>
			</h1>
			{game.currentQuestion !== 'lastQuestion' && leaderboard}
			<h2 style={{ textAlign: 'center', fontSize: '32px', padding: '48px' }}>
				Here's what people voted
			</h2>
			<div className='nes-table-responsive'>
				<table
					className='nes-table is-bordered is-centered'
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						fontSize: '36px',
						textAlign: 'center',
					}}>
					<thead>
						<tr>
							<th>Answers</th>
							<th>Votes</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{question.answers[0]}</td>
							<td>{countTotalAnswers['0'] || '0'}</td>
						</tr>
						<tr>
							<td>{question.answers[1]}</td>
							<td>{countTotalAnswers['1'] || '0'}</td>
						</tr>
						<tr>
							<td>{question.answers[2]}</td>
							<td>{countTotalAnswers['2'] || '0'}</td>
						</tr>
						<tr>
							<td>{question.answers[3]}</td>
							<td>{countTotalAnswers['3'] || '0'}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default PostQuestion;
