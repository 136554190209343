import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Firebase from './Firebase';

import 'nes.css/css/nes.min.css';
import './styles.css';

firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const playerDb = firebase.firestore().collection('player');
export const hostDb = firebase.firestore().collection('host').doc('SAM');
export const questionDb = firebase.firestore().collection('questions');

function Main() {
	return (
		<Router>
			<Firebase>
				<App />
			</Firebase>
		</Router>
	);
}

ReactDOM.render(
	<React.StrictMode>
		<Main />
	</React.StrictMode>,
	document.getElementById('root')
);
